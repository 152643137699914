
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import BetaTag from "@evercam/shared/components/BetaTag.vue"

export default {
  name: "TheSidebarItem",
  components: {
    BetaTag,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "self",
    },
    to: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    testId: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    betaFeature: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapStores(useLayoutStore),
    isCurrentActive() {
      return this.isActive !== null
        ? this.isActive
        : this.$route.fullPath === this.to
    },
  },
}
