
import { useLayoutStore } from "@/stores/layout"
import { AnalyticsEvent } from "@evercam/shared/types"
import { mapStores } from "pinia"

export default {
  computed: {
    ...mapStores(useLayoutStore),
    handleResizeStyle() {
      return {
        right: `calc(${this.layoutStore.rightSidebarWidth}px - 12px)`,
      }
    },
    drawerButtonStyle() {
      const isSmallScreenSidebar =
        this.layoutStore.isRightSidebarVisible &&
        this.$vuetify.breakpoint.mdAndDown

      return {
        right: `${
          isSmallScreenSidebar ? this.layoutStore.rightSidebarWidth : 0
        }px`,
        transform: `translateY(${
          this.$vuetify.breakpoint.mdAndDown ? -100 : -50
        }%)`,
      }
    },
  },
  watch: {
    "layoutStore.isRightSidebarResizeable"(value) {
      if (!value) {
        this.layoutStore.rightSidebarWidth =
          this.layoutStore.rightSidebarMinWidth
      }
    },
  },
  mounted() {
    this.$addEventListener("mousemove", this.onResizeMouseMove)
    this.$addEventListener("mouseup", this.onResizeMouseUp)
  },
  methods: {
    onResizeMouseDown() {
      if (!this.layoutStore.isRightSidebarResizeable) {
        return
      }
      this.layoutStore.isResizingRightSidebar = true
    },
    onResizeMouseMove(event) {
      if (!this.layoutStore.isRightSidebarResizeable) {
        return
      }
      if (this.layoutStore.isResizingRightSidebar) {
        this.layoutStore.rightSidebarWidth = Math.min(
          Math.max(
            window.innerWidth - event.clientX,
            this.layoutStore.rightSidebarMinWidth
          ),
          this.layoutStore.rightSidebarMaxWidth
        )
      }
    },
    onResizeMouseUp() {
      if (
        !this.layoutStore.isRightSidebarResizeable ||
        !this.layoutStore.isResizingRightSidebar
      ) {
        return
      }
      this.$analytics.saveEvent(AnalyticsEvent.layoutResizeRightSidebar, {
        width: this.layoutStore.rightSidebarWidth,
      })
      this.layoutStore.isResizingRightSidebar = false
    },
    toggleRightSidebar() {
      this.layoutStore.toggleRightSidebar()
      this.$analytics.saveEvent(AnalyticsEvent.layoutToggleRightSidebar, {
        visible: this.layoutStore.isRightSidebarVisible,
      })
    },
  },
}
