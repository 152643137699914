
export default {
  name: "AutomationsTabs",

  computed: {
    showTabs() {
      return this.$route.path !== "/v2/users/signout"
    },
  },
}
